.header {
  position: relative;
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 30%;
    }
    &__wrapper {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

		&__logo {
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      &-img {
        width: 60px;
        height: 60px;
      }
      &-descr {
        font-size: 30px;
        font-weight: 900;
        margin-left: 10px;
        color: #2b2b2b;
        text-transform: uppercase;
        line-height: 22px;

        }

      &-town {
          display: block;
          font-weight: 500;
          font-size: 16px;
          text-transform:none;
		}
  }
		&__contacts {
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
		}

		&__contact-item-btn {
      display: flex;
      background-color: #e31e25;
      border: 2px solid #ffffff;
      border-radius: 50px;
      cursor: pointer;
      margin-left: 25px;
		}
    &__contact-item-btn:hover {
      border-color: #e31e25;
      background-color: #ffffff;
		}
    &__contact-item-btn:hover > .header__btn {
      color: #2b2b2b;
		}
		&__phone-block {
      display: flex;
      flex-direction: column;
		}

		&__btn {
      font-size: 14px;
      padding: 8px 12px;
      color: #fff;
      font-weight: 400;
      text-align: center;
      span {
        display: block;
      }
		}

    &__phone {
      font-size: 20px;
      font-weight: 400;
      color: #2b2b2b;
    }

    &__mail {
      font-size: 17px;
      font-weight: 500;
      color:#2b2b2b;
    }
    &__mail-open {
      font-size: 17px;
      font-weight: 500;
      color:#2b2b2b;
    }
    &__code {
      color: #2b2b2b;
      vertical-align: top;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      display: inline-block;
      font-weight: 900;
    }
    &__num {
      color: #2b2b2b;
      height: 26px;
      line-height: 26px;
      display: inline-block;
      font-size: 26px;
      font-weight: 900;
    }

}

.hero {
  position: relative;
}

.hero__subtitle {
  position: absolute;
  left: 0;
  bottom: 100px;
  font-size: 18px;
  font-weight: 700;
  color: #2b2b2b;
  text-transform: uppercase;

}

.hero__img {
  width: 100%;
}

.hero__service {
  position: relative;
  z-index: 1;
  padding: 25px 15px;
}

.hero__service-block {
  transform: skew(-20deg);
  background-color: #feb600;
}

.hero__service-text {
  display: block;
  font-size: 24px;
  font-weight: 900;
  color: #2b2b2b;
  text-transform: uppercase;
  transform: skew(20deg);
  padding: 10px 10px;
  text-align: center;
  span {
    padding: 0 10px;
  }
}
.hero__service-bottom {
  position: relative;
  z-index: 1;
  padding: 25px 15px;
}
.hero__service-block-bottom {
  transform: skew(-20deg);
  background-color: #feb600;
}

.hero__service-text-bottom {
  display: block;
  font-size: 24px;
  font-weight: 900;
  color: #2b2b2b;
  text-transform: uppercase;
  transform: skew(20deg);
  padding: 10px 10px;
  text-align: center;
  span {
    padding: 0 10px;
  }
}

@media (max-width: 826px) {
  .hero__service-text {
    font-size: 20px;
  }
  .hero__service-text-bottom {
    font-size: 20px;
  }
  .header__wrapper {
    flex-direction: column;
  }
  .header__service {
    justify-content: center;
  }
  .header__contacts {
    margin-top: 20px;
  }
  .header {
    padding: 10px 0 0 0;
  }
  .header__logo-img {
    width: 30px;
    height: 30px;
  }
  .header__num {
    height: 26px;
    line-height: 29px;
  }
}
@media (max-width: 576px) {
  .header__logo-descr {
    font-size: 25px;
  }
  .hero__service-text {
    font-size: 18px;
  }
  .hero__service-text-bottom {
    font-size: 18px;
  }
  .hero__service {
    top: 10px;
  }
  .hero__service-text {
    padding: 3px 10px;
  }
}
@media (max-width: 483px)  {
  .header__btn {
    font-size: 12px;
  }
  .header__num {
    font-size: 24px;
  }
  .header__mail-open {
    font-size: 12px;
  }
  .header__contacts {
    width: 100%;
  }
  .header__service-text {
    font-size: 14px;
  }
  .header__service-text {
    font-size: 14px;
  }
  .header__service-text {
    font-size: 14px;
  }
  .header__btn {
    padding: 8px 7px;
  }
  .header__mail-open-wrap {
    display: flex;
    flex-direction: column;
  }
  .header__mail-open span{
    display: none;
  }
  .hero__service-text {
    font-size: 16px;
  }
  .hero__service-text-bottom {
    font-size: 16px;
  }
  .header__logo-town {
    font-size: 12px;
}
}


@media (max-width: 420px) {
  .swiper-slide-title {
    font-size: 18px;
    font-weight: 700;
  }
  .header__num {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
  }
  .header__contacts {
    margin-top: 5px;
  }
  .hero__service-text {
    font-size: 14px;
  }
  .hero__service-text-bottom {
    font-size: 14px;
  }
  .hero__service-text {
    padding: 3px 0;
  }
  .hero__service-bottom {
    bottom: 10px;
  }
  .hero__service-text-bottom {
    padding: 3px 0;
  }
  .header__mail {
    font-size: 15px;
  }
}

@media (max-width: 342px)  {
  .hero__service-text {
    font-size: 12px;
  }
  .hero__service-text-bottom {
    font-size: 12px;
  }
}
