.footer {
  padding: 35px 0;
  background-color: #2b2b2b;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    display: block;
    a {
    display: block;
    color: #ffffff;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: right;
    }
  }
}


@media (max-width: 768px) {
  .footer__item a {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .footer__right {
      flex-direction: column;
  }
  .footer__item a {
    text-align: center;
  }
  .footer__wrapper {
    justify-content: center;
  }
  .header__contact-item-btn--footer {
    margin: 0;
    margin-top: 20px;
  }
}
