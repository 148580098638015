.about_company {
    position: relative;
    padding: 50px 0;
    background: url(../img/company-bg.jpg) no-repeat center center;
    background-size: cover;
    min-height: 350px;

    p {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
    h2 {
      color: #ffffff;
      text-align: center;
      font-size: 30px;
      font-weight: 900;
      text-transform: uppercase;
      margin: 0;
    }
}


.about-exp {
    padding: 100px 0;
		&__wrapper {
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
		}

		&__card {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 33.3%;


		}

		&__card-img {
      height: 50px;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
		}

		&__card-text {
      display: block;
      text-align: center;
		}
}

@media (max-width: 826px)  {
  .about-exp {
    padding: 50px 0;
  }
}

@media (max-width: 768px) {
  .about-exp__card {
      max-width: none;
      width: 50%;
      padding-bottom: 20px;
  }
  .about-exp__wrapper {
    flex-wrap: wrap;
  }
  .about_company {
      padding: 30px 0;
  }
}
@media (max-width: 576px) {
  .about-exp__card {
      width: 100%;
  }
  .about_company p {
    font-size: 14px;
  }
}
