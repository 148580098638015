:root {
  --font-family: "Open Sans", sans-serif;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.header {
  position: relative;
}
.header__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 30%;
}
.header__wrapper {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.header__logo-img {
  width: 60px;
  height: 60px;
}
.header__logo-descr {
  font-size: 30px;
  font-weight: 900;
  margin-left: 10px;
  color: #2b2b2b;
  text-transform: uppercase;
  line-height: 22px;
}
.header__logo-town {
  display: block;
  font-weight: 500;
  font-size: 16px;
  text-transform: none;
}
.header__contacts {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__contact-item-btn {
  display: flex;
  background-color: #e31e25;
  border: 2px solid #ffffff;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 25px;
}
.header__contact-item-btn:hover {
  border-color: #e31e25;
  background-color: #ffffff;
}
.header__contact-item-btn:hover > .header__btn {
  color: #2b2b2b;
}
.header__phone-block {
  display: flex;
  flex-direction: column;
}
.header__btn {
  font-size: 14px;
  padding: 8px 12px;
  color: #fff;
  font-weight: 400;
  text-align: center;
}
.header__btn span {
  display: block;
}
.header__phone {
  font-size: 20px;
  font-weight: 400;
  color: #2b2b2b;
}
.header__mail {
  font-size: 17px;
  font-weight: 500;
  color: #2b2b2b;
}
.header__mail-open {
  font-size: 17px;
  font-weight: 500;
  color: #2b2b2b;
}
.header__code {
  color: #2b2b2b;
  vertical-align: top;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  display: inline-block;
  font-weight: 900;
}
.header__num {
  color: #2b2b2b;
  height: 26px;
  line-height: 26px;
  display: inline-block;
  font-size: 26px;
  font-weight: 900;
}

.hero {
  position: relative;
}

.hero__subtitle {
  position: absolute;
  left: 0;
  bottom: 100px;
  font-size: 18px;
  font-weight: 700;
  color: #2b2b2b;
  text-transform: uppercase;
}

.hero__img {
  width: 100%;
}

.hero__service {
  position: relative;
  z-index: 1;
  padding: 25px 15px;
}

.hero__service-block {
  transform: skew(-20deg);
  background-color: #feb600;
}

.hero__service-text {
  display: block;
  font-size: 24px;
  font-weight: 900;
  color: #2b2b2b;
  text-transform: uppercase;
  transform: skew(20deg);
  padding: 10px 10px;
  text-align: center;
}
.hero__service-text span {
  padding: 0 10px;
}

.hero__service-bottom {
  position: relative;
  z-index: 1;
  padding: 25px 15px;
}

.hero__service-block-bottom {
  transform: skew(-20deg);
  background-color: #feb600;
}

.hero__service-text-bottom {
  display: block;
  font-size: 24px;
  font-weight: 900;
  color: #2b2b2b;
  text-transform: uppercase;
  transform: skew(20deg);
  padding: 10px 10px;
  text-align: center;
}
.hero__service-text-bottom span {
  padding: 0 10px;
}

@media (max-width: 826px) {
  .hero__service-text {
    font-size: 20px;
  }
  .hero__service-text-bottom {
    font-size: 20px;
  }
  .header__wrapper {
    flex-direction: column;
  }
  .header__service {
    justify-content: center;
  }
  .header__contacts {
    margin-top: 20px;
  }
  .header {
    padding: 10px 0 0 0;
  }
  .header__logo-img {
    width: 30px;
    height: 30px;
  }
  .header__num {
    height: 26px;
    line-height: 29px;
  }
}
@media (max-width: 576px) {
  .header__logo-descr {
    font-size: 25px;
  }
  .hero__service-text {
    font-size: 18px;
  }
  .hero__service-text-bottom {
    font-size: 18px;
  }
  .hero__service {
    top: 10px;
  }
  .hero__service-text {
    padding: 3px 10px;
  }
}
@media (max-width: 483px) {
  .header__btn {
    font-size: 12px;
  }
  .header__num {
    font-size: 24px;
  }
  .header__mail-open {
    font-size: 12px;
  }
  .header__contacts {
    width: 100%;
  }
  .header__service-text {
    font-size: 14px;
  }
  .header__service-text {
    font-size: 14px;
  }
  .header__service-text {
    font-size: 14px;
  }
  .header__btn {
    padding: 8px 7px;
  }
  .header__mail-open-wrap {
    display: flex;
    flex-direction: column;
  }
  .header__mail-open span {
    display: none;
  }
  .hero__service-text {
    font-size: 16px;
  }
  .hero__service-text-bottom {
    font-size: 16px;
  }
  .header__logo-town {
    font-size: 12px;
  }
}
@media (max-width: 420px) {
  .swiper-slide-title {
    font-size: 18px;
    font-weight: 700;
  }
  .header__num {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
  }
  .header__contacts {
    margin-top: 5px;
  }
  .hero__service-text {
    font-size: 14px;
  }
  .hero__service-text-bottom {
    font-size: 14px;
  }
  .hero__service-text {
    padding: 3px 0;
  }
  .hero__service-bottom {
    bottom: 10px;
  }
  .hero__service-text-bottom {
    padding: 3px 0;
  }
  .header__mail {
    font-size: 15px;
  }
}
@media (max-width: 342px) {
  .hero__service-text {
    font-size: 12px;
  }
  .hero__service-text-bottom {
    font-size: 12px;
  }
}
.uslugi {
  padding-top: 100px;
  padding-bottom: 100px;
}
.uslugi__logo {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.uslugi__logo:before {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 1px;
  top: 50%;
  bottom: 0;
  left: -50px;
  background-color: #2b2b2b;
}
.uslugi__logo:after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 1px;
  top: 50%;
  bottom: 0;
  right: -50px;
  background-color: #2b2b2b;
}
.uslugi__title {
  margin: 0;
  color: #2b2b2b;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}
.uslugi__wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.uslugi__card {
  border: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.uslugi__card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.uslugi__card-img {
  min-width: 160px;
  height: 135px;
  object-fit: contain;
}
.uslugi__card-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: #2b2b2b;
  text-transform: uppercase;
  padding-left: 20px;
  flex-grow: 1;
}
.uslugi__cardsell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uslugi__cardsell-img {
  width: 50%;
}
.uslugi__cardsell-content {
  width: 50%;
}
.uslugi__cardsell-title {
  display: block;
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #FFFFFF;
  color: #2b2b2b;
  text-align: right;
}
.uslugi__cardsell-subtitle {
  display: block;
  font-size: 50px;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #FFFFFF;
  color: #2b2b2b;
  text-align: right;
}

@media (max-width: 826px) {
  .uslugi {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 886px) {
  .uslugi__cardsell-subtitle {
    font-size: 42px;
  }
  .uslugi__cardsell-title {
    font-size: 42px;
  }
}
@media (max-width: 768px) {
  .uslugi__wrapper {
    grid-template-columns: 1fr;
  }
  .uslugi__cardsell-subtitle {
    font-size: 35px;
  }
  .uslugi__cardsell-title {
    font-size: 35px;
  }
  .uslugi__card-title {
    padding: 0 20px;
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .uslugi__cardsell-subtitle {
    font-size: 25px;
  }
  .uslugi__cardsell-title {
    font-size: 25px;
  }
  .uslugi__title {
    font-size: 25px;
  }
}
@media (max-width: 420px) {
  .uslugi__cardsell-subtitle {
    font-size: 20px;
  }
  .uslugi__cardsell-title {
    font-size: 20px;
  }
  .uslugi__card-title {
    padding: 0 10px;
  }
  .uslugi__card-title {
    font-size: 16px;
  }
}
@media (max-width: 360px) {
  .uslugi__cardsell-subtitle {
    font-size: 16px;
  }
  .uslugi__cardsell-title {
    font-size: 16px;
  }
  .about_company h2 {
    font-size: 25px;
  }
  .uslugi__card-img {
    min-width: 120px;
  }
}
.about_company {
  position: relative;
  padding: 50px 0;
  background: url(../img/company-bg.jpg) no-repeat center center;
  background-size: cover;
  min-height: 350px;
}
.about_company p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.about_company h2 {
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
}

.about-exp {
  padding: 100px 0;
}
.about-exp__wrapper {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-exp__card {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 33.3%;
}
.about-exp__card-img {
  height: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.about-exp__card-text {
  display: block;
  text-align: center;
}

@media (max-width: 826px) {
  .about-exp {
    padding: 50px 0;
  }
}
@media (max-width: 768px) {
  .about-exp__card {
    max-width: none;
    width: 50%;
    padding-bottom: 20px;
  }
  .about-exp__wrapper {
    flex-wrap: wrap;
  }
  .about_company {
    padding: 30px 0;
  }
}
@media (max-width: 576px) {
  .about-exp__card {
    width: 100%;
  }
  .about_company p {
    font-size: 14px;
  }
}
.footer {
  padding: 35px 0;
  background-color: #2b2b2b;
}
.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer__right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__item {
  display: block;
}
.footer__item a {
  display: block;
  color: #ffffff;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: right;
}

@media (max-width: 768px) {
  .footer__item a {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .footer__right {
    flex-direction: column;
  }
  .footer__item a {
    text-align: center;
  }
  .footer__wrapper {
    justify-content: center;
  }
  .header__contact-item-btn--footer {
    margin: 0;
    margin-top: 20px;
  }
}