.uslugi {
  padding-top: 100px;
  padding-bottom: 100px;
    &__logo {
      display: flex;
      justify-content: center;
      position: relative;
      overflow: hidden;
    }
    &__logo:before{
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      height: 1px;
      top: 50%;
      bottom: 0;
      left: -50px;
      background-color: #2b2b2b;
    }
    &__logo:after{
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      height: 1px;
      top: 50%;
      bottom: 0;
      right: -50px;
      background-color: #2b2b2b;
    }
    &__title {
      margin: 0;
      color: #2b2b2b;
      text-align: center;
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
    }
		&__wrapper {
      padding-top: 50px;
      padding-bottom: 50px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
		}

		&__card {
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      background-color: #fff;
		}
    &__card:hover {
        box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    }
		&__card-img {
      min-width: 160px;
      height: 135px;
      object-fit: contain;
		}

		&__card-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      color: #2b2b2b;
      text-transform: uppercase;
      padding-left: 20px;
      flex-grow: 1;
		}
    &__cardsell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-img {
        width: 50%
      }
      &-content {
        width: 50%
      }
      &-title {
        display: block;
        font-size: 50px;
        font-weight: 400;
        text-transform: uppercase;
        text-shadow: 2px 2px 2px #FFFFFF;
        color: #2b2b2b;
        text-align: right;
      }
      &-subtitle {
        display: block;
        font-size: 50px;
        font-weight: 900;
        text-transform: uppercase;
        text-shadow: 2px 2px 2px #FFFFFF;
        color: #2b2b2b;
        text-align: right;
      }
    }
}

@media (max-width: 826px)  {
  .uslugi {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 886px) {
  .uslugi__cardsell-subtitle {
    font-size: 42px;
  }
  .uslugi__cardsell-title {
    font-size: 42px;
  }
}


@media (max-width: 768px) {
  .uslugi__wrapper {
    grid-template-columns: 1fr;
  }
  .uslugi__cardsell-subtitle {
    font-size: 35px;
  }
  .uslugi__cardsell-title {
    font-size: 35px;
  }
  .uslugi__card-title {
    padding: 0 20px;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .uslugi__cardsell-subtitle {
    font-size: 25px;
  }
  .uslugi__cardsell-title {
    font-size: 25px;
  }
  .uslugi__title {
    font-size: 25px;
  }
}

@media (max-width: 420px) {
  .uslugi__cardsell-subtitle {
    font-size: 20px;
  }
  .uslugi__cardsell-title {
    font-size: 20px;
  }
  .uslugi__card-title {
    padding: 0 10px;
  }
  .uslugi__card-title {
    font-size: 16px;
  }
}

@media (max-width: 360px) {
  .uslugi__cardsell-subtitle {
    font-size: 16px;
  }
  .uslugi__cardsell-title {
    font-size: 16px;
  }
  .about_company h2 {
    font-size: 25px;
  }
  .uslugi__card-img {
    min-width: 120px;
  }
}
